import { getCurrentYear } from "./helpers";

export const LANGUAGES = ["en", "de", "es", "ja", "ru", "it", "fr"];

export const COPYRIGHT = `© ${getCurrentYear()} Carista.`;

export const SOCIALS_LINK_FACEBOOK = "https://www.facebook.com/caristaapp";
export const SOCIALS_LINK_LINKEDIN = "https://www.linkedin.com/company/carista/";
export const SOCIALS_LINK_INSTAGRAM = "https://www.instagram.com/caristaapp/";
export const SOCIALS_LINK_TIKTOK = "https://www.tiktok.com/@caristaapp";
export const SOCIALS_LINK_YOUTUBE = "https://www.youtube.com/channel/UCeiMZzmLpzhH1qcZ-tezXmA";

export const VAG_MAKES = ["Audi", "Volkswagen", "Seat", "Skoda", "Cupra"];
export const ALL_MAKES = ["audi", "bmw", "cupra", "infinity", "lexus", "mini", "nissan", "scion", "seat", "skoda", "toyota", "vw", "ford", "lincoln", "land-rover"];

export const OPEN_GRAPH_ADAPTER_IMG_URL = `${process.env.VUE_APP_API}/assets/images/Carista-EVO-OBD2-Bluetooth-Scanner-nobg.png`;
